import React from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

const About = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "info" }} />
      <Layout>
        <Container className="info-contain">
          <Row>
            <Col lg={12}>
              <h1 className="page-title">About</h1>
            </Col>
            <Col lg={4} className="about-image">
              <StaticImage
                src="../assets/images/eg-portrait.jpg"
                alt="Art One"
                placeholder="blurred"
                layout="fullWidth"
              />
            </Col>
            <Col lg={8}>
              <p className="description">
                Senior Scientist with a solid background in the development of
                analytical workflows and methods within the drug discovery
                process. Advanced expertise in molecular biology, genetics, and
                human disease research; filling knowledge gaps in an
                interdisciplinary environment. Skilled in data collection,
                analysis, interpretation, and presentation of results
                (international conferences and peer-reviewed journals).
              </p>
              <p className="resume-text">
                <span className="bold">
                  Academic and industry R&amp;D sector experience.
                </span>
              </p>
              <p className="resume-text">
                <span className="bold">
                  Permanent US Resident / Green Card holder
                </span>
              </p>
              <p className="resume-text">
                <span className="bold author">
                  SCIENTIFIC AND PROFESSIONAL HIGHLIGHTS:
                </span>
              </p>
              <ul className="resume-list">
                <li>Experience with bioinformatics and (bio) statistics</li>
                <li>
                  Research, management and communication skills cultivated
                  across on 27+ interdisciplinary projects in the fields of
                  oncology, genomics, and evolutionary biology
                </li>
                <li>
                  Strong supervisory experience in supervising and training
                  bachelor, master, PhD junior scientists
                </li>
                <li className="highlight-link">
                  1 patent and 37 scientific{" "}
                  <Link to="/publications/all">publications</Link>
                </li>
                <li className="highlight-link">
                  <a href="https://scholar.google.es/citations?hl=en&user=1wjClfcAAAAJ&view_op=list_works">
                    Google Scholar
                  </a>
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold author">SKILLS:</span>
              </p>
              <ul className="resume-list">
                <li>
                  Molecular biology and gene identification (18 years
                  experience): DNA-RNA extraction, oligo design, DNA
                  amplification (PCR, Q-PCR, RT-PCR), Southern blotting,
                  fingerprint (REP- PCR), microsatellite and SNPs markers for
                  individual typing, and gene expression analysis. Construction,
                  quality assesment, and sequencing of genomic libraries (target
                  enrichment, and metagenomics). Sequencing technologies:
                  Sanger, Illumina.
                </li>
                <li>
                  Bioinformatics (15 years experience): experience in the use of
                  R and Mosix-based cluster environments, HPC clusters, Linux,
                  Mac OS. Experience in data management, Machine Learning
                  applications, strong analytical and (bio) statistical skills
                  and good understanding of High-Throughput data analysis (e.g.
                  Bionumerics, Spotfire).
                </li>
                <li>
                  Proteomics and protein chemistry (6 years experience): protein
                  purification and selective protein tagging/bioconjugation,
                  antibody preparation, purification and validation,
                  quantitative proteomics (2D-DIGE), gel electrophoresis
                  (SDS-PAGE, Western blotting).
                </li>
                <li>
                  Microbiology (4 years experince): bacterial culture and
                  isolation, antimicrobial susceptibility testing (AST) methods,
                  MIC assessment.
                </li>
                <li>
                  Virology (1 year experience): Herpetovirus production, plaque
                  assays and transfections of mammalian cell lines (VERO).
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold author">LINKS:</span>
              </p>
              <ul className="resume-list">
                <li className="highlight-link">
                  <a href="https://www.linkedin.com/in/elenaguacimara/?originalSubdomain=es">
                    LinkedIn
                  </a>
                </li>
                <li className="highlight-link">
                  <a href="https://scholar.google.es/citations?user=1wjClfcAAAAJ&hl=us">
                    Google Scholar
                  </a>
                </li>
                <li className="highlight-link">
                  <a href="https://publons.com/researcher/3881097/elena-g-gonzalez/">
                    Web of Science Researcher ID
                  </a>
                </li>
                <li className="highlight-link">
                  <a href="https://orcid.org/0000-0002-4614-3889">ORCID</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default About
